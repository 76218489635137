import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TrackingContext } from "../../context";
import { useForm } from "../../hooks";
import {
  Assicurazione,
  Contrassegno,
  DataRichiesta,
  Mittente,
  Destinatario,
  DettaglioMerce,
  Epal,
  MerceADR,
  Servizi,
  TotaleDatiMerce,
  Resa,
} from "./ArgomentiSpedizioniFormEdit";
import { green } from '@mui/material/colors';


const SpedizioniMainFormUpdate = () => {
  const navigate = useNavigate();
  const [title, setTitle] = React.useState("Modifica spedizione");
  const [open, setOpen] = React.useState(false);
  const [salvataggioInCorso, setSalvataggioInCorso] = React.useState(false);


  const handleClose = () => {
    navigate(-1);
  };

  const { idSpedizione } = useParams();

  const {
    state: { configSped, spedDetailEdit },
    getConfigModeSpedizioni,
    getEditSpedizione,
    pulisciSpedDetail,
    putSped,
  } = useContext(TrackingContext);
  
  useEffect(() => {
    if (idSpedizione) {
      getEditSpedizione(idSpedizione);
    }
  }, [idSpedizione]);

  useEffect(() => {
    getConfigModeSpedizioni();
    return () => {
      pulisciSpedDetail()
    }
  }, []);  

  const { values: addSpedizioniForm, setValues } = useForm(
    {
      UploadFile: [],
      DataPronta: moment().format("YYYY-MM-DD"),
      OraPronta: "",
      MittNazione: "IT",
      MittLocalita: "",
      MittProvincia: "",
      MittCAP: "",
      MittRagSoc: "",
      MittCodiceIndirizzoCodificato: "",
      MittIndirizzo: "",
      MittPersona: "",
      MittTelefono: "",
      MittEmail: "",
      MittRiferimento: "",
      NotaRitiro: "",
      Orari: "",
      IndicatoriMitt: [],

      DestNazione: "IT",
      DestLocalita: "",
      DestProvincia: "",
      DestCAP: "",
      DestRagSoc: "",
      DestCodiceIndirizzoCodificato: "",
      DestIndirizzo: "",
      DestPersona: "",
      DestTelefono: "",
      DestEmail: "",
      DestRiferimento: "",
      NotaConsegna: "",
      IndicatoriDest: [],

      DataRichiesta: null,

      ServizioPerWeb: " ",
      spedmerce: [],
      Tipo: " ",
      Peso: "",
      Volume: "",
      Quantita: "",
      Epal: null,
      tipoMerce:"",


      NotaDimensioniMerce: "",
      spedadr: [],
      TipoIncasso: " ",
      Contrassegno: "",
      ContrassegnoValuta: "EUR",

      ValMerce: "",
      ValMerceValuta: "EUR",
      Resa: "",
      ResaPerWeb:"",
      sceltaContrassegno: false,
      sceltaADR: false,
      sceltaAssicurazione: false,
    },
    []
  );

  useEffect(() => {
    if (Object.entries(spedDetailEdit).length > 0) {
      setValues(spedDetailEdit)
      setTimeout(() => {
        setOpen(true)
      }, 3000);
    }
  }, [spedDetailEdit]);

  return (
    <div>
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>

          <Stack direction={"row"} spacing={2}>
            <Button
              color="warning"
              size={"small"}
              variant={"contained"}
              onClick={handleClose}
              disabled={salvataggioInCorso}
            >
              annulla
            </Button>
            <Box sx={{position:'relative'}}>
              <Button
                color="success"
                size={"small"}
                variant={"contained"}
                onClick={() => putSped(addSpedizioniForm, handleClose, navigate, setSalvataggioInCorso)}
                disabled={salvataggioInCorso}
              >
                salva
              
              </Button>
              {
                  salvataggioInCorso && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                )}  
              </Box>
          </Stack>
        </Toolbar>
      </AppBar>
      {Object.entries(spedDetailEdit).length > 0  && open ? (
        <Box pt={9} pb={1}>
          {configSped.resa?.visible ? (
            <Resa
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
              vociResa={configSped.resa?.values}          
            />
          ) : (
            <></>
          )}


          {configSped.mittente?.visible ? (
            <Mittente
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
              riferimentoCaption={configSped.mittente.riferimento.caption}
              riferimentoVisible={configSped.mittente.riferimento_visible}
              luogoRitiroVisible={configSped.mittente.default_LuogoRitiro}
              readOnly={configSped.mittente.readonly}
              indicatoriConfig={configSped.mittenteindicatori}
            />
          ) : (
            <></>
          )}
          {configSped.destinatario?.visible ? (
            <Destinatario
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
              riferimentoCaption={configSped.destinatario.riferimento.caption}
              riferimentoVisible={configSped.destinatario.riferimento_visible}
              luogoRitiroVisible={configSped.destinatario.default_LuogoRitiro}
              readOnly={configSped.destinatario.readonly}
              indicatoriConfig={configSped.destinatarioindicatori}
            />
          ) : (
            <></>
          )}
          {configSped.datarichiesta?.visible ? (
            <DataRichiesta
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
              caption={configSped.datarichiesta.caption}
            />
          ) : (
            <></>
          )}
          {configSped.servizio?.visible ? (
            <Servizi setMainForm={setValues} mainForm={addSpedizioniForm} />
          ) : (
            <></>
          )}
          {configSped.dettagliomisuremerce?.visible ? (
            <DettaglioMerce
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
              TipoMerceList={configSped.tipomerce}

            />
          ) : (
            <></>
          )}
          {configSped.totalemerce?.visible ? (
            <TotaleDatiMerce
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
            />
          ) : (
            <></>
          )}
          <Epal setMainForm={setValues} mainForm={addSpedizioniForm} />
          {configSped.contrassegno?.visible ? (
            <Contrassegno
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
            />
          ) : (
            <></>
          )}
          {configSped.merceadr?.visible ? (
            <MerceADR setMainForm={setValues} mainForm={addSpedizioniForm} />
          ) : (
            <></>
          )}
          {configSped.assicurazione?.visible ? (
            <Assicurazione
              setMainForm={setValues}
              mainForm={addSpedizioniForm}
              title={configSped.assicurazione?.title}
            />
          ) : (
            <></>
          )}
          <Stack direction={"row-reverse"} spacing={2} mt={2}>
            <Box sx={{position:'relative'}}>
              <Button
                color="success"
                size={"medium"}
                variant={"contained"}
                onClick={() => putSped(addSpedizioniForm, handleClose, navigate, setSalvataggioInCorso)}
                disabled={salvataggioInCorso}>
                salva
              </Button>
              {
                salvataggioInCorso && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
              )}  
            </Box>
            <Button
              color="warning"
              size={"medium"}
              variant={"contained"}
              onClick={handleClose}
              disabled={salvataggioInCorso}
            >
              annulla
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box pt={9} pb={1} sx={{flexDirection:"row", justifyContent:"center"}}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default SpedizioniMainFormUpdate;
