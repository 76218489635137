import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { TrackingContext } from "../context";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { iconStateTracking } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import AddLinkIcon from '@mui/icons-material/AddLink';
import { NotificationContent } from "../components";
import { sendNotification } from "../hooks/useNotification";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

const markerIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  // specify the path here
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png",
});

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(0,223,245,0.49093140674238445) 0%, rgba(45,253,194,0.208018241476278) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(0,223,245,0.49093140674238445) 0%, rgba(45,253,194,0.208018241476278) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#303030",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(0deg, rgba(112,245,0,0.7906512946975666) 0%, rgba(45,253,194,0.8774860285911239) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(0deg, rgba(59,145,240,0.7906512946975666) 0%, rgba(45,238,253,0.8774860285911239) 100%);",
  }),
}));

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
  description: PropTypes.node,
};

function ColorlibStepIcon(props) {
  const { active, completed, className, icon, description } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {iconStateTracking(icon, description)}
    </ColorlibStepIconRoot>
  );
}

const position = [41.90333804221134, 12.479559542007335];


const DettaglioTracking = () => {
  const [pos, setPos] = useState(position);
  const [locationMap, setLocationMap] = useState(false);
  const [mostraMap, setMostraMap] = useState(false);
  const inputEl = useRef(null);
  const [NPage, setNPage] = useState(0)
  const [ pageOffset, setPageOffset ] = useState(0);
  const [pageResetVar, setPageResetVar] = useState(true)




  const {
    state: { 
      trackingDetail,
      columnsCustomDetail,
      stepper,
      DownloadPodDetail,
      PrgSpedizione,    
    },
    getTrackingDetail,
  } = useContext(TrackingContext);

  const { idTracking } = useParams();
  console.log(PrgSpedizione)

  useEffect(() => {
    if (idTracking) {
      getTrackingDetail(idTracking);
    }
    

  }, []);

  const reindirizzaMappa = (row) => {
    if (row.Lat !== "" || row.Lon !== "") {
      setMostraMap(true);
      setPos({ lat: row.Lat, lng: row.Lon });
      inputEl.current.flyTo({ lat: row.Lat, lng: row.Lon });
      inputEl.current.getZoom();
    } else {
      setMostraMap(false);
      setLocationMap(true);
    }
  };


  const downloadPod = ()  => {
    let path = localStorage.getItem("path");
    let splitPrm = path.split("/");

    //19/06/2024 Greg: Non utilizzo setPodDownloadVar, altrimenti rischio pagine vuota per tempi di esecuzione dell'hook
    //setPodDownloadPath(`${splitPrm[0]}//${splitPrm[2]}/pod/${params[idElement]}`)
    let podDownloadPathVar = `${splitPrm[0]}//${splitPrm[2]}/pod/${PrgSpedizione}`

    window.open(
      podDownloadPathVar,
      "_blank",
      "noopener,noreferrer"
    )
    podDownloadPathVar = ''
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLocationMap(false);
  };

  const createLinkandCopy = () => {
    let basePath = window.location.href;
    let splitPrm = basePath.split("/");
    let url = `${splitPrm[0]}//${splitPrm[2]}/public/view/tracking/${trackingDetail.IDTracking}`;

    //11/03/2024 Greg: funzione per copiare un testo su clipboard.
    //TODO: Spostare in utility.js
    //source: https://www.freecodecamp.org/news/copy-text-to-clipboard-javascript/
    const copyContent = async (text) => {
      try {
        await navigator.clipboard.writeText(text);

      } catch (err) {
        sendNotification(
            <NotificationContent service={"Link pubblico"} />,
            "Si è verificato un errore durante la copia.",
            "error",
            10
          );  
          return;
      }
      sendNotification(
        <NotificationContent service={"Link pubblico"} />,
        "Copia avvenuta con successo!",
        "success",
        10
      ); 
    }
    
    copyContent(url);      
  };

  const mapSingleStep = (label, key) =>{
    return(
    <Step key={label.code} active={label.status}>
      <StepLabel
        StepIconComponent={ColorlibStepIcon}
        StepIconProps={{
          icon: label.code,
          description: label.description,
        }}
      >
        {console.log(label)}
        <Typography fontSize={11}>{label.description}</Typography>
      </StepLabel>
    </Step>
    )

  }

  return (
    <>
      <Stack direction={"row-reverse"} spacing={2} mt={2} mb={2}>
        <Tooltip title={"Copia link per il tracking pubblico"}>
          <Button
          variant="contained"
          
          startIcon={<AddLinkIcon />}
          //disabled={checkBoxSelectionPrint.length < 1}
          onClick={() => createLinkandCopy()}
          >
            Link pubblico
          </ Button>  
        </Tooltip>

        {/* {
          PackingListDetail?.Visible && PackingListDetail.Link ? (
            <Tooltip title={"Visualizza Packing List"}>
              <Button
              variant="contained"
              
              startIcon={<InsertDriveFileIcon />}
              //disabled={checkBoxSelectionPrint.length < 1}
              onClick={() => {
                window.open(
                  `${PackingListDetail.Link}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
              >
                Packing List
              </ Button>  
            </Tooltip>
          ):(<></>)
        } */}

        {
          DownloadPodDetail?.Visible  ? (
              <Tooltip title={"Visualizza POD"}>
                <Button
                variant="contained"
                
                startIcon={<ContentPasteIcon />}
                //disabled={checkBoxSelectionPrint.length < 1}
                onClick={() => {
                    downloadPod()
                }}
                >
                  POD
                </ Button>  
              </Tooltip>
              ):(<></>)
            }
          </Stack>

    <Grid container spacing={2}>
      <Snackbar
        open={locationMap}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Coordinate non presenti
        </Alert>
      </Snackbar>


      {trackingDetail?.displaytext1 !== "" ? (
        <Grid item xs={12} md={6} lg={6} style={{display : 'flex'}}>
        <Alert severity="success" icon={<></>}  style={{ flex: 1 }}>
            {
              <ReactMarkdown
                children={trackingDetail ? trackingDetail?.displaytext1 : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
     {trackingDetail?.displaytext2 !== "" ? (
        <Grid item xs={12} md={6} lg={6} style={{display : 'flex'}}>
          <Alert severity="success" icon={<></>}  style={{ flex: 1 }}>
            {
              <ReactMarkdown
                children={trackingDetail ? trackingDetail?.displaytext2 : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
      {trackingDetail?.displaytext3 !== "" ? (
        <Grid item xs={12} md={12} lg={12}>
          <Alert severity="warning" icon={<></>}>
            {
              <ReactMarkdown
                linkTarget="_blank"
                children={trackingDetail ? trackingDetail?.displaytext3 : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
      <Grid item container spacing={2} direction={"row"} ml={2} mr={2}>
        <Grid item xs={12} md={mostraMap ? 8 : 12} lg={mostraMap ? 8 : 12}>
          <Stack spacing={2} direction="column">
            <Stack spacing={4}>
              <Stepper
                alternativeLabel
                // activeStep={
                //   stepper.filter((res) => res.status === true).length - 1
                // }
                nonLinear={true}
                connector={<ColorlibConnector />}
              >
                {stepper?.map((label, key) => (                  
                  mapSingleStep(label, key)
                ))}
              </Stepper>
            </Stack>
            {columnsCustomDetail.length > 0 && trackingDetail.data.length > 0 ? (
              trackingDetail.displaySpedtracking ? (
                <CustomPaginationActionsTable
                  rows={trackingDetail.data ? trackingDetail.data : []}
                  columns={columnsCustomDetail}
                  limit={100}
                  recordCount={0}
                  actionShow={false}
                  setPageReset={setPageResetVar}
                  setCounterApi = {setPageOffset}
                  page={NPage}
                  setPage = {setNPage}
                  viewAction={{
                    mail: false,
                    view: false,
                    location: true,
                    print: false,
                  }}
                  routeDetail={(e) => reindirizzaMappa(e)}
                  getMethodForList={(e) => {
                    return null;
                  }}
                />
              ) : (
                <Stack justifyContent={"center"} alignContent="center">
                  {/* <Typography>Caricamento...</Typography>
                <LinearProgress color="success" /> */}
                </Stack>
              )
            ) : (
              <Stack justifyContent={"center"} alignContent="center">
                {/*  <Typography>Caricamento...</Typography>
                <LinearProgress color="success" /> */}
              </Stack>
            )}
          </Stack>
        </Grid>
        {mostraMap ? (
          <Grid item xs={12} md={4} lg={4}>
            <Box
              sx={{
                border: 1,
                borderWidth: 1,
                borderColor: "black",
                height: "100%",
                width: "100%",
                //margin:10
              }}
            >
              <MapContainer
                ref={inputEl}
                center={pos}
                zoom={15}
                scrollWheelZoom={false}
                whenCreated={(map) =>
                  setInterval(() => {
                    map.invalidateSize();
                  }, 100)
                }
                style={{ width: "100%", height: "70vh" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={pos} icon={markerIcon}>
                  <Popup minWidth={90}>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              </MapContainer>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
    
    {/* <Stack direction={"row-reverse"} spacing={2} mt={2} mb={2}>
        <Tooltip title={"Copia link per il tracking pubblico"}>
          <Button
          variant="contained"
          
          startIcon={<AddLinkIcon />}
          //disabled={checkBoxSelectionPrint.length < 1}
          onClick={() => createLinkandCopy()}
          >
            Copia link pubblico
          </ Button>  
        </Tooltip>
      </Stack> */}
    </>
  );
};

export default DettaglioTracking;
