import {
  Alert,
  Grid,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  Tooltip
} from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import { TrackingContext } from "../context";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { iconStateTracking } from "../utilities";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';



const markerIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  // specify the path here
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png",
});

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(0,223,245,0.49093140674238445) 0%, rgba(45,253,194,0.208018241476278) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(0,223,245,0.49093140674238445) 0%, rgba(45,253,194,0.208018241476278) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#303030",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(0deg, rgba(112,245,0,0.7906512946975666) 0%, rgba(45,253,194,0.8774860285911239) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(0deg, rgba(59,145,240,0.7906512946975666) 0%, rgba(45,238,253,0.8774860285911239) 100%);",
  }),
}));

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
  description: PropTypes.node,
};

function ColorlibStepIcon(props) {
  const { active, completed, className, icon, description } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {iconStateTracking(icon, description)}
    </ColorlibStepIconRoot>
  );
}

const position = [41.90333804221134, 12.479559542007335];

const DettaglioSpedizioni = () => {
  const [pos, setPos] = useState(position);
  const [locationMap, setLocationMap] = useState(false);
  const [mostraMap, setMostraMap] = useState(false);
  const inputEl = useRef(null);

  const {
    state: {
      spedDetail, 
      columnsCustomDetail,
      stepper,
      DownloadPodDetail, 
    },
    getSpedizioneDetail,
  } = useContext(TrackingContext);
  

  const { idSpedizione } = useParams();

  useEffect(() => {
    if (idSpedizione) {
      getSpedizioneDetail(idSpedizione);
    }
  }, [idSpedizione]);

  const reindirizzaMappa = (row) => {
    if (row.Lat !== "" || row.Lon !== "") {
      setMostraMap(true);
      setPos({ lat: row.Lat, lng: row.Lon });
      inputEl.current.flyTo({ lat: row.Lat, lng: row.Lon });
      inputEl.current.getZoom();
    } else {
      setMostraMap(false);
      setLocationMap(true);
    }
  };

  const downloadPod = ()  => {
    let path = localStorage.getItem("path");
    let splitPrm = path.split("/");

    //19/06/2024 Greg: Non utilizzo setPodDownloadVar, altrimenti rischio pagine vuota per tempi di esecuzione dell'hook
    //setPodDownloadPath(`${splitPrm[0]}//${splitPrm[2]}/pod/${params[idElement]}`)
    let podDownloadPathVar = `${splitPrm[0]}//${splitPrm[2]}/pod/${idSpedizione}`

    window.open(
      podDownloadPathVar,
      "_blank",
      "noopener,noreferrer"
    )
    podDownloadPathVar = ''
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLocationMap(false);
  };

  return (

    <>
      <Stack direction={"row-reverse"} spacing={2} mt={2} mb={2}>

        {
          DownloadPodDetail?.Visible  ? (
            <Tooltip title={"Visualizza POD"}>
              <Button
                variant="contained"
                
                startIcon={<ContentPasteIcon />}
                //disabled={checkBoxSelectionPrint.length < 1}
                onClick={() => {
                    downloadPod()
                }}
              >
                POD
              </ Button>  
            </Tooltip>
          ):(<></>)
        }
      </Stack>

      <Grid container spacing={2}>
        <Snackbar
          open={locationMap}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Coordinate non presenti
          </Alert>
        </Snackbar>
        {spedDetail?.displaytext1 !== "" ? (
          <Grid item xs={12} md={6} lg={6}>
            <Alert severity="info" icon={<></>}>
              {
                <ReactMarkdown
                  children={spedDetail ? spedDetail?.displaytext1 : ""}
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
      {spedDetail?.displaytext2 !== "" ? (
          <Grid item xs={12} md={6} lg={6}>
            <Alert severity="success" icon={<></>}>
              {
                <ReactMarkdown
                  children={spedDetail ? spedDetail?.displaytext2 : ""}
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
        {spedDetail?.displaytext3 !== "" ? (
          <Grid item xs={12} md={12} lg={12}>
            <Alert severity="warning" icon={<></>}>
              {
                <ReactMarkdown
                  children={spedDetail ? spedDetail?.displaytext3 : ""}
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item container spacing={2} direction={"row"} ml={2} mr={2}>
          <Grid item xs={12} md={mostraMap ? 8 : 12} lg={mostraMap ? 8 : 12}>
            <Stack spacing={2} direction="column">
                <Stack spacing={4}>
                  <Stepper
                    alternativeLabel
                    activeStep={
                      stepper.filter((res) => res.status === true).length - 1
                    }
                    connector={<ColorlibConnector />}
                  >
                    {stepper?.map((label, key) => (
                      <Step key={label.code}>
                        <StepLabel
                          StepIconComponent={ColorlibStepIcon}
                          StepIconProps={{
                            icon: label.code,
                            description: label.description,
                          }}
                        >
                          <Typography fontSize={11}>
                            {label.description}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DettaglioSpedizioni;
